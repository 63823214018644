
























































import { Component, Mixins } from 'vue-property-decorator';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import GroupComponent from '@/mixins/group-component';
import { BackupAssetEntityModel, BackupAssetQueryModel } from '@/entity-model/backup-asset-entity';
import { BackupAssetService } from '@/service/backup-asset';
import BackupAssetDetailDrawer from './backup-asset-detail-drawer.vue';

@Component({
    components: {
        'backup-asset-detail-drawer': BackupAssetDetailDrawer
    }
})
export default class SparePartsListComponent extends Mixins(TableDialogFormComponent, GroupComponent) {
    BackupAssetEntityModel = BackupAssetEntityModel;
    created() {
        this.initTable({
            service: new BackupAssetService(),
            queryModel: new BackupAssetQueryModel(),
            tableColumns: BackupAssetEntityModel.getTableColumns()
        });
        this.getList();
    }
    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as BackupAssetQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/backup-asset?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/backup-asset');
        }
    }
    detailClick(model: BackupAssetEntityModel) {
        (this.$refs.backupAssetDetailDrawer as BackupAssetDetailDrawer).drawerShow(model);
    }
    formDialogOK() {
        this.getList();
    }
}

