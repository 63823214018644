























































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { GroupEntityType } from '@/model/enum';
import { BackupAssetService } from '@/service/backup-asset';
import { BackupAssetEntityModel } from '@/entity-model/backup-asset-entity';

@Component
export default class BackupAssetLinkDialog extends BaseComponent {
    queryLoading: boolean = false;
    dialogVisible: boolean = false;
    GroupEntityType = GroupEntityType;
    parentId: string;
    rightDataList: Array<BackupAssetEntityModel> = null;
    backupService: BackupAssetService;
    listData: Array<BackupAssetEntityModel> = null;
    searchText: string = null;

    created() {
        this.backupService = new BackupAssetService();
    }

    get FilterListData() {
        const trimText = _.toUpper(_.trim(this.searchText));
        if (trimText) {
            return _.filter(this.listData, item => _.toUpper(item.name).indexOf(trimText) > -1);
        }
        return this.listData;
    }

    dialogOpen(parentId: string) {
        this.parentId = parentId;
        this.dialogVisible = true;

        this.queryLoading = true;
        Promise.all([this.backupService.getBatchList(parentId), this.backupService.getLinkedAssetList(parentId)]).then(res => {
            this.listData = _.filter(_.get(res, '[0]'), item => item.id !== parentId);
            this.rightDataList = _.get(res, '[1]');
        }).catch(err => {
            this.showContactAdmin();
            this.dialogClose();
        }).finally(() => {
            this.queryLoading = false;
        });
    }

    dialogOK() {
        return this.backupService.link(this.parentId, _.map(this.rightDataList, item => item.id)).then((ret) => {
            this.dialogClose();
            this.$emit('dialogOK', ret);
        }).catch(err => {
            throw err;
        });
    }

    dialogClose(): void {
        this.listData = null;
        this.rightDataList = null;
        this.parentId = null;
        this.dialogVisible = false;
    }

    checkOnChange(model: BackupAssetEntityModel) {
        if (model.check) {
            this.rightDataList.push(model);
        } else {
            const index = _.findIndex(this.rightDataList, item => item.id === model.id);
            if (index > -1) {
                this.rightDataList.splice(index, 1);
            }
        }
    }

    removeItemClick(model, index) {
        model.check = false;
        const listDataItem = _.find(this.listData, item => item.id === model.id);
        if (listDataItem) {
            listDataItem.check = false;
        }
        this.rightDataList.splice(index, 1);
    }
    removeAllClick() {
        this.rightDataList = [];
        _.forEach(this.listData, item => {
            item.check = false;
        });
    }
}
