











































































import { Component, Mixins } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { BackupAssetEntityModel } from '@/entity-model/backup-asset-entity';
import { BackupAssetLinkedEntityModel } from '@/entity-model/backup-asset-linked-entity';
import FormDialogComponent from '@/mixins/form-dialog-component';
import { BackupAssetService } from '@/service/backup-asset';
import { ViewModeType } from '@/model/enum';
import BackupAssetLinkDialog from './backup-asset-link-dialog.vue';

@Component({
    components: {
        'backup-asset-link-dialog': BackupAssetLinkDialog
    }
})
export default class BackupAssetDetailDrawer extends Mixins(DrawerComponent, TableDialogFormComponent) {
    backupAssetModel: BackupAssetEntityModel = new BackupAssetEntityModel();
    backupAssetLinkedEntityModel: BackupAssetLinkedEntityModel=new BackupAssetLinkedEntityModel();
    tabIndex: string = '1';
    assetId: string = null;
    created() {
        this.initTable({
            queryModel: new BackupAssetLinkedEntityModel(),
            tableColumns: BackupAssetLinkedEntityModel.getTableColumns()
        });
    }
    initTab() {
        new BackupAssetService().getLinkedAssetList(this.backupAssetModel.id).then(res => {
            this.listData = res;
        });
    }
    drawerShow(model: BackupAssetEntityModel) {
        this.backupAssetModel = model;
        this.drawerOpen(model.id, model.name);
        this.init(model.id);
    }

    init(assetId: string) {
        this.assetId = assetId;
        this.startFullScreenLoading();
        new BackupAssetService().retrieve(assetId)
            .then(res => {
                this.backupAssetModel = res;
            })
            .catch(error => {
                this.backupAssetModel = new BackupAssetEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        this.tabIndex = '1';
        this.init(this.assetId);
    }
    reloadLinked() {
        this.initTab();
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<BackupAssetEntityModel>).dialogOpen(this.backupAssetModel, new BackupAssetService(), ViewModeType.UPDATE);
    }
    linkChildClick(model: BackupAssetEntityModel) {
        (this.$refs.formLinkDialog as BackupAssetLinkDialog).dialogOpen(this.backupAssetModel.id);
    }
    changeTab(e) {
        if (e === '2') {
            this.initTab();
        }
    }
}
