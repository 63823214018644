import { BaseEntityModel } from '@/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@/model/form-control';
import { ThingsTemplateQueryModel } from './things-template-entity';
import { QueryControl, QueryControlType } from '@/model/query-control';

export class BackupAssetEntityModel extends BaseEntityModel {
    constructor(groupId?: string) {
        super();
        this.groupId = groupId;
    }

    @FormControl({
        label: '备件编号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    code: string = undefined;

    @FormControl({
        label: '备件名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '规格型号',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    type: string = undefined;

    @FormControl({
        label: '数量',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    count: string = undefined;

    @FormControl({
        label: '品牌',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    brand: string = undefined;

    @FormControl({
        label: '供应商',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    supplier: string = undefined;

    @FormControl({
        label: '供应商电话',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    phone: string = undefined;

    @FormControl({
        label: '单位',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    unit: string = undefined;

    check: boolean = undefined;
    groupId: string = undefined;

    static getTableColumns() {
        return [
            {
                title: '备件编号',
                dataIndex: 'code',
                scopedSlots: { customRender: 'code' }
            },
            {
                title: '备件名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '规格型号',
                dataIndex: 'type',
                scopedSlots: { customRender: 'type' }
            },
            {
                title: '品牌',
                dataIndex: 'brand',
                scopedSlots: { customRender: 'brand' }
            },
            {
                title: '计量单位',
                dataIndex: 'unit',
                scopedSlots: { customRender: 'unit' }
            },
            {
                title: '数量',
                dataIndex: 'count',
                scopedSlots: { customRender: 'count' }
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.groupId = this.groupId;
        return data;
    }
}

export class BackupAssetQueryModel extends ThingsTemplateQueryModel {
    @QueryControl({
        label: '备件',
        type: QueryControlType.TEXT
    })
    name: string = '';

    // @QueryControl({
    //     label: '适配设备',
    //     type: QueryControlType.TEXT
    // })
    // deviceName:string='';

    toService() {
        const data:any = {};
        data.params = {
            groupIds: this.GroupIds,
            name: this.name
            // deviceName: this.deviceName
        };
        return data;
    }
}
