import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from './base-component';

@Component
export default class DrawerComponent extends BaseComponent {
    placement: string = 'right';
    width: string = '70%';
    closable: boolean = true;
    drawerVisible: boolean = false;
    drawerTitle: string = null;

    @Ref()
    drawContentComponent: any;

    drawerOpen(data: any, title?: string) {
        if (title) {
            this.drawerTitle = title;
        }
        this.drawerVisible = true;
        this.$nextTick(() => {
            if (this.drawContentComponent) {
                this.drawContentComponent.init(data);
            }
        });
    }
    drawerClose() {
        this.drawerVisible = false;
    }
}
